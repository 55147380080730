:root {
    /* COLOUR PALETTE */
    --light-shade: #f4f3ed;
    --light-accent: #eba84a;
    --mid-grey-shade: #c6cada;
    --dark-accent: #794233;
    --dark-shade: #363736;
}

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

/* DISPLAY: FLEX */
.Nav,
.Nav ul li,
.Nav .user-greeting,
.Footer,
.Article .author-and-topic,
.Comments ul li .votes,
.Comments ul li form,
.ErrorMessage,
.Login,
.Login form {
    display: flex;
}
.Nav ul li,
.Footer,
.Login,
.Login form {
    flex-direction: column;
}

/* DISPLAY: BLOCK */
.Logo,
.Logo a,
.Articles ul,
.Article section {
    display: block;
}

/* TEXT-ALIGN: CENTER */
.Logo,
.Logo a span,
.NotFound p.emoji {
    text-align: center;
}

/* TEXT-ALIGN: RIGHT */
.Nav .user-greeting p,
.Comments ul li .votes p {
    text-align: right;
}

/* ------------------------------------------------------------------- */

body {
    min-width: 320px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: var(--light-shade);
}

/* LOGO */
.Logo {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
    background-color: var(--light-accent);
    text-transform: uppercase;
    height: fit-content;
    font-size: 2rem;
}
.Logo h1,
.Nav h1 {
    font-size: inherit;
    font-weight: inherit;
    display: inherit;
}
.Logo a span {
    background-color: black;
    color: white;
    height: 2.5rem;
    padding-top: 0.1rem;
    width: 2.5rem;
    display: inline-block;
    margin-right: 7px;
}
.Logo a span.Logo-news {
    background-color: transparent;
    color: var(--dark-shade);
    width: fit-content;
    margin-right: 0;
}

/* NAV */
.Nav {
    background-color: var(--mid-grey-shade);
    align-items: center;
    justify-content: space-between;
}
.Nav ul li {
    list-style-type: none;
}
.Nav ul li a {
    color: var(--dark-accent);
    font-weight: bold;
    text-transform: capitalize;
    padding: 5px;
}
.Nav ul li a:hover,
.Nav ul li a.selected-topic {
    background-color: var(--light-shade);
}
.Nav .sign-up-link a {
    font-size: 1.2em;
}
.Nav .sign-up-link a,
.Nav .user-greeting a {
    color: var(--dark-accent);
}
.Nav .user-greeting {
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.Nav .user-greeting img.avatar {
    width: 50px;
    border-radius: 50%;
    height: 50px;
    margin-left: 10px;
}

/* FOOTER */
.Footer {
    background-color: var(--light-accent);
    color: var(--dark-shade);
    font-size: 1.1em;
}
.Footer p {
    padding: 5px 0;
}
.Footer a {
    font-weight: bold;
    color: var(--dark-shade);
    text-decoration: none;
    padding-left: 15px;
    padding-right: 15px;
}
.Footer a:first-child {
    padding-left: 0px;
    border-right: 2px var(--dark-shade) solid;
}
.Footer a:hover {
    text-decoration: underline;
}

/* ARTICLES */
.Articles ul {
    list-style-type: none;
}
.Articles ul li {
    margin: 10px 0;
}
.Articles ul li img {
    width: 100%;
}
.Articles ul li a {
    text-decoration: none;
    color: inherit;
}
.Articles ul li strong {
    text-decoration: underline;
}
.Articles ul li .Articles--topic {
    color: var(--dark-accent);
}
.Articles select {
    width: 100%;
    padding: 10px;
    font-family: inherit;
}

/* ARTICLE */
.Article h1 {
    font-size: 1.5em;
}
.Article .author-and-topic {
    justify-content: space-between;
    border-bottom: 2px solid var(--light-accent);
    margin-bottom: 10px;
}
.Article .author-and-topic p {
    padding: 10px 0;
    font-size: 1.1em;
    color: var(--dark-accent);
}
.Article .author-and-topic strong {
    color: var(--dark-shade);
}
.Article section img {
    width: 100%;
    border: 2px black solid;
    border-radius: 3px;
}
.Article section .article-body {
    padding: 10px 0;
    font-size: 1.1em;
}
.Article .related-content {
    background-color: var(--light-accent);
    padding: 10px;
    border-radius: 5px;
}
.Article .related-content ul li {
    list-style-type: square;
    list-style-position: inside;
    margin-top: 5px;
}
.Article .related-content ul li a,
.Article .related-content ul li a:visited {
    list-style-type: square;
    list-style-position: inside;
    margin-top: 5px;
    color: var(--dark-shade);
}

/* COMMENTS */
.Comments {
    background-color: var(--mid-grey-shade);
    border-radius: 5px;
    margin: 10px;
}
.Comments h2 {
    border-bottom: 2px dashed var(--dark-shade);
    padding-bottom: 5px;
    color: var(--dark-shade);
}
.Comments ul li {
    list-style-type: none;
    background-color: var(--light-shade);
    border: var(--dark-accent) 2px solid;
    border-radius: 10px;
    margin: 5px 0;
}
.Comments ul li .title {
    border-bottom: var(--dark-accent) 2px solid;
    padding-bottom: 3px;
    margin-bottom: 3px;
}
.Comments ul li .title .delete {
    float: right;
    font-family: Consolas, "Courier New", Courier, monospace;
    text-decoration: none;
    color: var(--dark-accent);
}
.Comments ul li .comment {
    white-space: pre-wrap;
}
.Comments ul li .votes {
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background-color: var(--mid-grey-shade);
    border-radius: 3px;
    padding: 3px;
    margin-top: 5px;
    font-size: 1.2em;
}
.Comments ul li .votes a {
    flex-grow: 1;
    text-decoration: none;
    color: var(--dark-shade);
    padding: 3px;
    width: 100px;
    border: 1px solid transparent;
}
.Comments ul li .votes p {
    flex-grow: 2;
    margin-left: 10px;
    margin-right: 10px;
}
.Comments ul li form {
    flex-wrap: wrap;
}
.Comments ul li form textarea {
    flex-grow: 6;
    font-size: 1em;
    max-width: 100%;
    margin: 2px;
    padding: 5px;
    font-family: inherit;
}
.Comments ul li form button {
    font-size: 0.9em;
    background-color: #e6ebff;
    flex-grow: 1;
    padding: 5px;
    min-height: 30px;
    margin: 2px;
    border: 2px solid var(--dark-shade);
    color: var(--dark-shade);
    border-radius: 5px;
    font-family: inherit;
}
.Comments ul li .votes .voted {
    font-weight: bold;
    background-color: var(--light-accent);
    border-radius: 3px;
    border: 1px solid var(--dark-shade);
    color: #222222;
}

/* COMMENTS ERROR MESSAGE */
.ErrorMessage {
    border: solid 2px var(--dark-accent);
    border-radius: 3px;
    background-color: pink;
    margin-top: 5px;
    flex-wrap: wrap;
    align-items: center;
}
.ErrorMessage div {
    font-size: 2em;
}

/* LOGIN PAGE */
h1.Login {
    text-align: center;
}
.Login section {
    margin-bottom: 20px;
}
.Login form label {
    margin: 10px 0;
}
.Login form label select {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
}
.Login form button {
    padding: 10px;
    background-color: rgb(56, 150, 56);
    color: var(--light-shade);
    font-weight: bold;
    font-size: 1.5em;
    border: none;
    border-radius: 5px;
}

.NotFound p {
    margin-top: 10px;
    margin-bottom: 15px;
}
.NotFound p.emoji {
    font-size: 50vw;
}

/* PADDING FOR STRUCTURAL COMPONENTS */
.Footer,
.Logo,
.Nav,
.Articles,
.Article,
.Comments,
.Comments li,
.ErrorMessage,
.Login,
.NotFound {
    padding: 10px;
}

@media only screen and (min-width: 700px) {
    /* LOGO */
    .Logo {
        text-align: left;
        font-size: 2.5rem;
    }
    .Logo a span {
        height: 3rem;
        padding-top: 0.1rem;
        margin-right: 10px;
        width: 3rem;
    }

    /* NAV */
    .Nav ul li {
        border-left: 2px var(--dark-shade) solid;
        padding: 0 15px;
        display: inline-block;
    }
    .Nav ul li:nth-child(1) {
        border-left: none;
        padding-left: 0;
        margin-left: -5px;
    }

    /* FOOTER */
    .Footer {
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    /* ARTICLES */
    .Articles select {
        width: 30%;
        min-width: 300px;
        display: block;
        margin: 0px auto;
        border: var(--dark-shade) 2px solid;
        border-radius: 3px;
    }
    .Articles ul {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    }
    .Articles ul li:nth-child(1),
    .Articles ul li:nth-child(2) {
        grid-column: span 3;
    }
    .Articles ul li {
        grid-column: span 2;
        margin: 20px;
    }
    .Articles ul li img {
        width: 100%;
    }

    /* ARTICLE */
    .article-parent {
        display: flex;
    }
    .Article {
        width: 100%;
    }
    .Article section img {
        margin: 15px 15px 0 30px;
        float: right;
        width: 40%;
        box-shadow: 0px 0px 30px var(--dark-accent);
    }
    .Article .related-content {
        padding: 15px;
    }

    /* COMMENTS */
    .Comments {
        width: 30%;
        min-width: 30%;
        margin-right: 40px;
    }
    .Comments ul li .votes p {
        text-align: left;
    }
    .Comments ul li .title .delete {
        font-weight: bold;
        font-size: 0.9em;
    }

    /* LOGIN */
    .Login {
        flex-direction: row;
    }
    .Login section {
        width: 50%;
    }
    .Login section:nth-child(1) {
        border-right: 3px var(--mid-grey-shade) solid;
        padding-right: 15px;
    }
    .Login section:nth-child(2) {
        padding-left: 15px;
    }

    /* 404 PAGE */
    .NotFound {
        text-align: center;
    }
    .NotFound p.emoji {
        font-size: 20em;
    }

    /* PADDING FOR STRUCTURAL COMPONENTS */
    .Articles,
    .Comments,
    .Comments li,
    .ErrorMessage {
        padding-left: 20px;
        padding-right: 20px;
    }

    .Footer,
    .Logo,
    .Nav,
    .Article,
    .Login,
    .NotFound {
        padding-left: 40px;
        padding-right: 40px;
    }
}
